export default function Address({ coupon }) {


    let all_status = {
        "0": "Pending",
        "1": "Placed",
        "2": "Delivered",
        "3": "Dispatch",
        "4": "Return",
        "5": "Cancelled",
        "6": "Rejected",
        "7": "Return Accepted",
        "8": "Return Rejected",
        "9": "Return initiated",
        "10": "No Service",
        "11": "Confirmed",
        "12": "InTransit",
        "13": "Refund Price",
        "14": "Replacement Order",
        "15": "RTW Damage",
        "16": "RTW_Rto",
        "17": "LostInTransit",
        "18": "InTransitDamaged",
        "19": "PickupInitiated",
        "20": "PickupReceived",
        "25": "Completed"
    }

    return <>
        {coupon?.address?.street && <>
            <p className="mc-coupon-label">Address</p>
            <h3 className="mc-coupon-title">

                {coupon?.address?.street || "___"}

            </h3>
        </>}
        {coupon?.address?.city && <>
            <p className="mc-coupon-label">City</p>
            <h3 className="mc-coupon-title">

                {coupon?.address?.city || "___"}

            </h3>
        </>}
        {coupon?.address?.state && <>
            <p className="mc-coupon-label">State</p>
            <h3 className="mc-coupon-title">

                {coupon?.address?.state || "___"}

            </h3>
        </>}
        {coupon?.address?.district && <>
            <p className="mc-coupon-label">District</p>
            <h3 className="mc-coupon-title">

                {coupon?.address?.district || "___"}

            </h3>
        </>}
        {coupon?.address?.zipCode && <>
            <p className="mc-coupon-label">Pin Code</p>
            <h3 className="mc-coupon-title">

                {coupon?.address?.zipCode || "___"}

            </h3>
        </>}
        {coupon?.address?.tracking_number && <>
            <p className="mc-coupon-label">Tracking Number</p>
            <h3 className="mc-coupon-title">

                {coupon?.address?.tracking_number || "___"}

            </h3>
        </>}
        {coupon?.address?.delivery_partner && <>
            <p className="mc-coupon-label">Delivery Partner</p>
            <h3 className="mc-coupon-title">

                {coupon?.address?.delivery_partner || "___"}

            </h3>
        </>}
        {coupon?.address?.status && <>
            <p className="mc-coupon-label">Status</p>
            <h3 className="mc-coupon-title">

                {all_status[`${coupon?.address?.status}`] || "___"}

            </h3>
        </>}


    </>
}