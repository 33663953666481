import { Modal } from "react-bootstrap";

export default function OrderConfirmationModal({ coupon, orderConfirmationModal, setOrderConfirmationModal }) {

    let { address } = coupon
    return <>
        <Modal

            show={orderConfirmationModal}
            onHide={() => setOrderConfirmationModal(false)}
            onBackdropClick={() => setOrderConfirmationModal(false)}
            onClose={() => setOrderConfirmationModal(false)}
            centered
        >

            <Modal.Header closeButton></Modal.Header>

            <Modal.Body>

                <div className="mc-tracking-modal-content">

                    <ul>
                        <li>
                            <span>
                                Thankyou for your order, You will get order details soon.
                            </span>
                        </li>
                    </ul>

                    {false && <ul>
                        <li><span>Order Number</span><span></span></li>
                        <li><span>Delivery Address</span><span> Agra, UP - 282001</span></li>
                        <li><span>Status</span><span>In Transit</span></li>
                        <li><span>Tracking Link</span><span></span></li>
                        <li><span>Last Updated at</span><span>{new Date().toISOString()}</span></li>
                    </ul>}
                </div>

            </Modal.Body>

        </Modal>
    </>
}