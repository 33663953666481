import React, { useState, useEffect } from "react";
import { FaHeart } from "react-icons/fa";

const Footer = () => {
  const [currentDomain, setCurrentDomain] = useState('');



  useEffect(() => {
    setCurrentDomain(window.location.hostname);
  }, []);

  return (
    <div>
      <footer class="footer bg-dark text-center text-white">
        <div className="text-center p-3  d-md-flex justify-content-between">
          <div className="ps-sm-3 mb-md-0 mb-2">
            Copyright 2024.
            <a
              className="text-white"
              style={{ textDecoration: "none" }}
              href="https://almonds.ai/"
            >
              {" "}
              <u style={{ textDecoration: "none", color: "#ea8423" }}>
                Almonds AI |
              </u>{" "}
              All rights reserved.
            </a>
          </div>
          <div className="pe-sm-3">
            Made with <span className="red"><FaHeart /></span> by{""}
            <img
              src={
                currentDomain === 'mastercardjnk.vouch.club'
                  ? "/images/J&KDarkLogo.png"
                  : "/images/almond-logo-dark.png"
              }
              alt=""
              width={100}
              style={{ marginLeft: "2px" }}
            />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
