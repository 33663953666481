import { useEffect, useState } from "react";
import DigitalCouponRedeemInstructions from "../Items/Details/Digital/Instruction";
import ProductItem from "../Items/ProductItem";

export default function ProductList({ products, coupons, handleCoupons, currPage }) {


    const [isRedeemedOneCoupon, setIsRedeemedOneCoupon] = useState(null);

    console.log(coupons);

    useEffect(() => {

        const interval = setTimeout(() => {

            if (coupons) {

                let isRedeemed = coupons.find(el => el.status == 2);
                setIsRedeemedOneCoupon(isRedeemed);

            }

        }, 10);

        return () => clearInterval(interval);

    }, [coupons]);

    return <div className="mc-product-list">
        <div>
            {coupons && (coupons?.rows || coupons).map((item, index) => {
                return <ProductItem
                    coupon={item}
                    index={index}
                    handleCoupons={handleCoupons}
                    currPage={currPage}
                    isRedeemedOneCoupon={isRedeemedOneCoupon}
                />
            })}
            {
                coupons && ((coupons[0]?.type === "digital" && coupons[0]?.brand_name?.toLowerCase() === "amazon") && <DigitalCouponRedeemInstructions />)
            }
        </div>
    </div>
}